.product-information {
    margin-top: 20px;

    .info-section {
        margin-bottom: 10px;

        .info-title {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            padding-bottom: 10px;
            text-transform: uppercase;
            border-bottom: 1px solid #ccc;
          }
          
          .info-title p {
            margin: 0;
            font-size: 16px;
            display: flex;
            align-items: center;
            font-family: 'Monsterrat-Bold', sans-serif;
          }
          
          .icon {
            margin-left: 9px; 
            font-size: 17px;
          }
          
          .info-text {
            margin-top:20px;
            font-size: 14px;
            color: #333;
            margin-bottom: 20px;
            font-family: 'Monsterrat-Regular', sans-serif;
          }        
    }
    
    .custom-list {
        list-style-type: disc;
        margin-left: 20px;
        padding-left: 0;
      }
      
      .custom-list li {
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-family: 'Monsterrat-Regular', sans-serif;
      }
  }

  @media (max-width: 991px) {
    .product-information {
      padding: 0 15px;
    }
}