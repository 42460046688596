.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 15px;

  .card-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    gap: 6px;
    justify-content: center;
    width: auto;
    margin-top: 10px;
    position: relative;
    top: 0;

    /* Responsive Layout */
    @media (max-width: 1366px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-grid {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        transform: translateY(-3px);
      }

      .card {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: auto;
        border: none;

        .card-header {
          display: flex;
          background-color: transparent;
          border: none;
          justify-content: space-between;
          font-size: 20px;
          color: #000;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 10;

          .arrow-wrapper {
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(244, 244, 244, 0.7);
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
            position: relative;
          }

          .arrow-image-left {
            height: 83px;
            width: 127px;
            object-fit: contain;
            margin-left: -4px;
          }

          .arrow-image-right {
            height: 83px;
            width: 127px;
            object-fit: contain;
            margin-right: -4px;
          }

          @media (min-width: 1366px) {

            .arrow-image-left,
            .arrow-image-right {
              height: 83px;
              width: 127px;
            }
          }

          @media (max-width: 1365px) and (min-width: 1037px) {

            .arrow-image-left,
            .arrow-image-right {
              height: 83px;
              width: 127px;
            }
          }

          @media (max-width: 1036px) and (min-width: 770px) {

            .arrow-image-left,
            .arrow-image-right {
              height: 76px;
              width: 109px;
            }
          }

          @media (max-width: 769px) {

            .arrow-image-left,
            .arrow-image-right {
              height: 83px;
              width: 127px;
            }
          }
        }

        .card-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          color: black;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1em;
          box-sizing: border-box;
          background-color: transparent;
          border: none;
          font-size: 18px;

          .icon-bookmark,
          .icon-gem {
            font-size: 1.5em;
            transition: color 0.3s ease-in-out;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5em;
            height: 1.5em;
          }

          @media (max-width: 769px) {

            .icon-bookmark,
            .icon-gem {
              font-size: 1.2em;
            }
          }


          .gem-background {
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background-color: black;
            border-radius: 50%;
            z-index: 0;
          }

          .icon-gem.selected .gem-icon {
            color: white;
            z-index: 1;
          }

          @media (min-width: 1366px)and (min-width: 480px) {
            font-size: 18px;
          }
        }

        .card-image-container {
          width: 100%;
          max-height: 398px;
          overflow: hidden;
        }

        .card-image-container img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-details-footer {
        background-color: #ffffff;
        text-align: center;
        flex-direction: column;
        padding: 15px 0;

        .card-material {
          font-size: 12px;
          font-family: 'Mont-Regular', sans-serif;
        }

        .card-title {
          font-size: 20px;
          font-family: 'Mont-Bold', sans-serif;
        }

        .card-price {
          font-size: 18px;
          font-family: 'Mont-Regular', sans-serif;
        }

        .card-size-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          .card-size-content {
            display: flex;
            overflow-x: auto;
            scroll-behavior: smooth;
            scrollbar-width: none;
            gap: 5px;
            padding: 0 3px;

            .card-size-box {
              background-color: #F4F4F4;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              font-size: 11px;
              text-align: center;
              height: 30px;
              white-space: nowrap;
            }
          }
        }

        .size-box {
          background-color: #F4F4F4;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          font-size: 11px;
          text-align: center;
          height: 30px;
          white-space: nowrap;
        }

        .color-circle {
          margin-top: 5px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
        }

        .color-circle-split {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-block;
        }

        .icon-gem {
          font-size: 26px;

          @media (min-width: 1366px) {
            font-size: 26px;
          }

          @media (max-width: 1365px) and (min-width: 1037px) {
            font-size: 26px;
          }

          @media (max-width: 1036px) and (min-width: 770px) {
            font-size: 26px;
          }

          @media (max-width: 769px) and (min-width: 480px) {
            border: solid1px black;
            font-size: 40px;
          }

        }

        .text {
          font-weight: bold;
        }

        button {
          background: none;
          border: none;
          font-size: 20px;
          cursor: pointer;
          color: #000;
        }

        button:focus {
          outline: none;
        }
      }

      .card-material,
      .card-title,
      .card-price {
        margin: 0;
        padding: 0;
        line-height: 1.3;
      }
    }
  }
}

.show-more-button {
  padding: 10px 40px;
  background-color: #000000;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Mont-Regular', sans-serif;
}

@media screen and (max-width: 768px) {
  .card-size-box {
    font-size: 10px;
    height: 25px;
  }
}

/* Mobile (480px and below) */
@media screen and (max-width: 480px) {
  .card-size-content {
    width: 100px;
  }
}


.QuickShopPopuppopup-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .quickshop-header {
    display: flex;
    justify-content: flex-end;

    .quickshop-header-icon {
      cursor: pointer;
    }
  }

  .quickshop-color {
    margin-bottom: 2px;
    font-family: 'Mont-Regular', sans-serif;
  }

  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .color-circle-split {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
  }

  .quickshop-size {
    margin-bottom: 2px;
    font-family: 'Mont-Regular', sans-serif;
  }

  .size-dropdown {
    position: relative;
    font-family: "Mont-Regular";
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .dropdown-header {
    padding: 8px;
    background-color: white;
    border-radius: 4px;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 110px;
    overflow-y: auto;
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0;

  }
  
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-list li:hover {
    background-color: #F4F4F4; /* Custom hover effect */
  }
  
  .size-box {
    background-color: #F4F4F4;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 8px;
  }

  .quickshop-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .quickshop-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      color: #fff;
      border: none;
      width: 200px;
      height: 39px;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
      font-family: 'Mont-Regular', sans-serif;

      .gem-icon {
        margin-left: 12px;
        font-size: 18px;
      }
    }
  }
}