.cookie-popup {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000000;
    border: 1px solid white;
    color: white;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    padding: 10px 40px;

    @media (max-width: 1070px){
        top: 7%;
    }

    .cookie-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media (max-width: 1070px){
            overflow-y: auto;
            max-height: 85vh; 
            -webkit-overflow-scrolling: touch;
        }

        .cookie-popup-title {
            margin-bottom: 10px;

            p {
                font-size: 30px;
                text-align: center;
                font-family: 'Safira March Font', sans-serif;

                @media (max-width: 1070px){
                    font-size: 24px;
                }
            }
        }

        .cookie-popup-text {
            padding: 0 60px;
            margin: 10px 0;

            @media (max-width: 1070px){
                padding: 0 30px;
            }
    
            p {
                text-align: center;
                font-family: 'Mont-Regular', sans-serif;

                @media (max-width: 1070px){
                    font-size: 12px;
                }
            }
        }

        .cookie-popup-link {
            margin-bottom: 30px;

            p {
                text-align: center;
                font-family: 'Mont-Regular', sans-serif;
                margin: 0;

                @media (max-width: 1070px){
                    font-size: 12px;
                }
            }
        }

        .cookie-popup-butttons{
            display: flex;
            gap: 50px;
            justify-content: center;

            .cookie-popup-buttonsave{
                font-family: 'Mont-Regular', sans-serif;
                color: white;
                border: 1px solid white;
                background-color: #000000;
                padding: 10px 20px;

                @media (max-width: 1070px){
                    font-size: 12px;
                    padding: 5px 10px;
                }
            }

            .cookie-popup-buttonaccept{
                font-family: 'Mont-Regular', sans-serif;
                color: white;
                border: 1px solid #79946F;
                background-color: #79946F;
                padding: 10px 20px;

                @media (max-width: 1070px){
                    padding: 5px 10px;
                    font-size: 12px;
                }
            }
        }
        
    }

    .cookie-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        width: 70%;

        @media (max-width: 1070px){
            width: 100%;
        }
   
        .switch-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            font-size: 12px;

            @media (max-width: 1070px){
                padding: 0 5px;
            }

            .slider {
                margin: 10px 0;
                width: 46px;
                height: 26px;
                background: white;
                border-radius: 20px;
                position: relative;
                cursor: pointer;
                transition: background 0.3s;
            }
            
            .slider .toggle {
                width: 22px;
                height: 22px;
                background: #79946F;
                border-radius: 50%;
                position: absolute;
                top: 2px;
                left: 2px;
                transition: transform 0.3s;
            }
            
            .slider.on .toggle {
                transform: translateX(20px);
            }
        }
    }


    /* Overlay background */
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
    }
}