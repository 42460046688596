.slider-container {
    overflow: hidden;

    .scroll-button {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        z-index: 1;
        transition: background-color 0.3s;
    }

    .scroll-button.up {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .scroll-button.down {
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
      
    .scroll-button:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .slider {
        height: auto;
        display: flex;
        flex-direction: column;
        transition: transform 0.5s ease-in-out;

        .slider-image {
            width: 100%;
            height: auto;
            object-fit: cover;
            cursor: pointer;
            border: 1px solid transparent;
            margin-bottom: 5px;
        }
    }
}

.large-image-container {
    img {
        width: 100%;
    }
}

/* Hides Vertical Slider on Tablets & Phones */
@media (max-width: 991px) {
    .slider-container {
      display: none; 
    }
}

.horizontal-slider-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    padding: 10px 2px;
}

.horizontal-slider img {
    width: 80px;
    height: 80px;
    margin: 0 5px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.2s ease-in-out;
}

  