
.navigation-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; 
    
    .navigation-header-container{
        width: 100%;
        height: 38px;
        background-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navigation-header-text-b2b{
            font-family: 'Monsterrat-Regular', sans-serif;
            font-size: 12px;

            a{
                color: white;
                text-decoration: none;
            }
        }

        /* Large screens */
        @media (min-width: 1071px) {
            
            .navigation-header-text-b2b{
                font-size: 12px;
                margin-top: 15px;
            }
        }

        /* Medium screens */
        @media (max-width: 1070px) and (min-width: 666px) {
            .navigation-header-text-price{
                font-size: 12px;
                margin-top: 15px;
            }

            .navigation-header-text-div{
                display: flex;
                align-items: center;
            }

            img {
                height: 20px;
            }
         
        }

        /* Small screens (tablet & phone) */
        @media (max-width: 665px) {
            .navigation-header-text-price{
                font-size: 10px;
                margin-top: 15px;
            }
    
            .navigation-header-text-div{
                display: flex;
                align-items: center;
            }
    
            img{
                height: 15px;
            }   
        }

    }

    .navigation-select-container{
        position: relative;
        width: 100%;

        .navigation-hamburger.active{
            background: black;
            height: 70px;

            .navigation-hamburger-center{
                width: 20%;

                 /* Medium screens */
                 @media (max-width: 1070px) and (min-width: 666px) {
                    width: 50%;
                }

                /* Small screens (tablet & phone) */
                @media (max-width: 665px) {
                    width: 100%;
                }

                /* Extra small screens (mobile) */
                @media (max-width: 480px) {
                    width: 110%;
                }

                .navigation-logo-div{
                    height: 70px;

                    a{
                        text-decoration: none;
                        color: inherit;
                        pointer-events: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        img{
                            max-width: 60%;
                            max-height: 60%;
                        }
                    }
                }
            }
        }

        

        .navigation-hamburger{
            width: 100%;
            height: 120px;
            display: flex;

            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0));
            
            &.show-navigation{
                background: black;
            }
              
            .navigation-hamburger-left{
                width: 40%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 55px;

                .navigation-text-div{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon-BsList{
                        margin-top: 7;
                    }
                    .icon-BsXLg{
                        margin-top: 5;
                    }                    
                }

                @media (max-width: 1070px) {
                    .navigation-hamburger-left {
                        display: flex;
                        align-items: center;
                
                        .icon-BsSearch {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }

            .navigation-hamburger-center{
                width: 22%;

                 /* Medium screens */
                 @media (max-width: 1070px) and (min-width: 666px) {
                    width: 50%;
                }

                /* Small screens (tablet & phone) */
                @media (max-width: 665px) {
                    width: 50%;
                }

                /* Extra small screens (mobile) */
                @media (max-width: 480px) {
                    width: 80%;
                }

                .navigation-logo-div{
                    width: auto;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a{
                        text-decoration: none;
                        color: inherit;
                        pointer-events: auto;

                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            .totalInFavorites{
                position: relative;
                display: flex;
                background-color: rgba(0, 0, 0, 0.7);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                justify-content: center;
                top: -10px;
                left: -10px;
                font-size: 12px;
                font-family: 'Mont-Regular';
            }
            
            .addToCart{
                position: relative;
                display: flex;
                background-color: rgba(0, 0, 0, 0.7);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                justify-content: center;
                top: -10px;
                left: -10px;
                font-size: 12px;
                font-family: 'Mont-Regular';
            }

            .navigation-hamburger-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 55px;
            }
        }
    }
}
