.product-card {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .product-top-card {
        position: relative;
        display: inline-block;
    
        .product-image {
            width: 16em;
            height: auto;
            object-fit: cover;
            display: block;
        }
        .product-arrow-left {
            height: 76px;
            width: 103px;
            cursor: pointer;
            position: absolute;
            top: 1px;
            left: 10px;
            transform: rotate(180deg);
        }
        .product-arrow-right {
            height: 76px;
            width: 103px;
            cursor: pointer;
            position: absolute;
            top: 1px;
            right: 10px;
        }

        .product-image-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1em;
            box-sizing: border-box;

            .text {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 18px;
            }
            
            .icon-bookmark,
            .icon-gem {
                font-size: 1.5em;
                transition: color 0.3s ease-in-out;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5em;
                height: 1.5em;
            }

            .gem-background {
                position: absolute;
                width: 1.5em;
                height: 1.5em;
                background-color: black;
                border-radius: 50%;
                z-index: 0;
            }
            
            .icon-gem.selected .gem-icon {
                color: white;
                z-index: 1;
            }
        }
    }

    .product-description {
        text-align: center;
        height: auto;
        
        .product-material{
            font-size: 12px;
            margin: 0;
        }
        .product-title {
            font-size: 18px;
            margin: 0;
            padding: 2px 0;
        }

        .product-price{
            font-size: 15px;
            margin: 0;
            padding: 2px 0;
        }

        .product-size-container {
            display: flex;
            align-items: center;
            justify-content: center; 
            gap: 8px;
            margin-top: 10px;
            margin-bottom: 10px;

            .product-size-card {
                display: flex;
                overflow-x: auto;
                scroll-behavior: smooth;
                scrollbar-width: none;
                gap: 5px;
                max-width: 190px;
                padding: 0 3px;

                .card-size-box {
                    background-color: #F4F4F4;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 10px;
                    font-size: 11px;
                    text-align: center;
                    height: 30px;
                    white-space: nowrap;
                }
            }

            .size-box {
                background-color: #F4F4F4;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                font-size: 11px;
                text-align: center;
                height: 30px;
                white-space: nowrap;
              }
        }

        .color-circle {
            margin-top: 5px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
          }
  
          .color-circle-split {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-block;
          }
    }
}

/* Tablet (768px and below) */
@media screen and (max-width: 768px) {
    .product-card{
        .product-top-card {
            .product-image {
                width: 12em;
            }
            .product-arrow-left {
                height: 64px;
                width: 85px;
            }
            .product-arrow-right {
                height: 64px;
                width: 85px;
            }
            .product-image-footer {
                padding: 0.5em;

                .text {
                    font-size: 15px;
                }

                .icon-bookmark,
                .icon-gem {
                    font-size: 1.2em;
                }

                .gem-background {
                    width: 1.4em;
                    height: 1.4em;
                }
            }
        }
   
        .product-description {

            .product-title {
                font-size: 16px;
                margin: 0;
                padding: 2px 0;
            }
        
            .product-price{
                font-size: 15px;
                margin: 0;
                padding: 2px 0;
            }

            .favorites-color-circle{
                width: 15px;
                height: 15px;
            }

            .product-size-container{

                .product-size-card {
                    max-width: 120px;

                    .card-size-box {
                        font-size: 10px;
                        height: 25px;
                    }
                }
            }
        }
    }
}

/* Mobile (480px and below) */
@media screen and (max-width: 480px) {
    .product-card{
        .product-top-card {
            
            .product-image {
                width: 10em;
            }
            
            .favorites-arrow-image {
                height: 52px;
                width: 70px;
            }

            .favorites-card-footer{

                .text {
                    font-size: 12px;
                }

                .icon-bookmark,
                .icon-gem {
                    font-size: 1em;
                }

                .gem-background {
                    width: 1.4em;
                    height: 1.4em;
                }
            }
        }

        .product-description {

            .product-size-container{

                .product-size-card {
                    max-width: 100px;
                }
            }
        }
    }
}