.added-products-container {
    margin: 10px 20px;
    padding: 30px 40px;

    .added-products-header {
        font-family: Mont-Regular;
        font-size: 25px;
    }

    .added-products-div {
        padding: 10px;
        overflow-y: auto;

        .added-product {
            display: flex;
            align-items: center;
            padding: 20px;

            .added-product-image img {
                height: 215px;
                object-fit: cover;
                margin-right: 50px;
            }

            .added-product-details {
                flex-direction: column;

                .added-product-details-row {
                    display: flex;
                    align-items: center;

                    .added-product-title {
                        font-size: 18px;
                        padding-right: 10px;
                        margin: 0;
                    }

                    .added-product-color {
                        display: flex;
                        align-items: center;

                        .added-product-color-box {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }

                .added-product-price {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    margin-bottom: 7px;
                }

                .added-product-counter {
                    display: flex;
                    align-items: center;

                    .counter-button {
                        background-color: transparent;
                        border: none;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        text-align: center;
                        color: black;
                    }

                    .added-product-counter-value {
                        font-family: 'Mont-Regular';
                    }
                }
            }
        }

        .added-products-empty-cart {
            text-align: center;
            font-family: Mont-Regular;
            font-size: 15px;
        }
    }

    .added-products-input-container {
        padding: 20px 0;

        .added-products-input-fields {
            display: flex;
            justify-content: center;
            margin: 4px 0;

            .added-products-input-discount {
                height: 34px;
                width: 408px;
                font-size: 15px;
                padding: 0 10px;
                margin-bottom: 5px;
                border: 1px solid black;
                outline: none;
                font-family: 'Mont-Regular';
            }

            .added-products-input-arrow-right {
                position: relative;
                display: flex;
                left: -35px;
                bottom: -4px;
                cursor: pointer;
            }
        }
    }

    .added-products-price-and-shipping{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px;
        padding-top: 20px;

        p{
            font-size: 20px;
            font-family: 'Mont-Regular';
        }
    }

    .added-products-empty-cart{
        text-align: center;
        font-size: 20px;
        font-family: 'Mont-Bold';
    }
}