.shoppingcart-container {
    position: fixed;
    top: 3.5%;
    right: 2px;
    background-color: #fff;
    z-index: 9999; 
    border: 1px solid #000;
    width: 494px;
    height: 95vh;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .shoppingcart-header-icon {
        cursor: pointer;
        margin-left: auto;
    }

    .shoppingcart-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        flex-direction: column;

        .shoppingcart-header-text {
            margin-top: 5px;
            text-align: center;
        }
    }

    .shoppingcart-products {
        padding: 10px;
        overflow-y: auto;
    }

    .cart-item-container {
        display: flex;
        align-items: center;
        padding: 20px;
      }
      
      .cart-item-image img {
        width: 146;
        height: 182px;
        object-fit: cover;
        margin-right: 20px;
      }
      
      .cart-item-details {
        flex-direction: column;
      }
    
      .cart-item-details-row {
        display: flex;
        align-items: center;
      }
      
      .cart-item-title {
        font-size: 18px;
        padding-right: 10px;
        margin: 0;
      }
      
      .cart-item-color {
        display: flex;
        align-items: center;
      }
      
      .color-box {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
      
      .cart-item-price {
        margin: 0;
        padding: 0;
        font-size: 16px;
        margin-bottom: 7px;
      }
      
      .cart-item-counter {
        display: flex;
        align-items: center;
      }

      .counter-button {
        background-color: transparent;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        color: black;
      }

      .cart-total-sum {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 18px;
        font-weight: bold;
      }

    .shoppingcart-button {
        margin-top: 20px;
        display: block;
        width: 190px;
        height: 30px;
        background-color: #000;
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 0 15px;
        text-transform: uppercase;
    }
    
    .keepshopping-button {
        margin-top: 10px;
        color: #000;
        display: block;
        border: 1px solid #000;
        background-color: #fff;
        width: 190px;
        height: 30px;
        //padding: 0 15px;
        cursor: pointer;
        text-transform: uppercase;
    }
  }
  /* Overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  /* Media query for mobile devices */
@media (max-width: 767px) {
  .shoppingcart-container {
      width: 100vw;
      height: 97vh;
      right: 0;
      padding: 20px; 
      border: none;
  }

  .shoppingcart-header {
      padding: 15px;
  }

  .shoppingcart-header-icon {
      margin-left: auto;
  }

  .shoppingcart-button, .keepshopping-button {
      width: 100%;
      height: 40px;
      font-size: 16px;
  }

  .cart-item-image img {
      width: 100px;
      height: 130px;
  }

  .cart-item-container {
      padding: 10px;
  }

  .cart-item-price {
      font-size: 14px;
  }
}
